export type DepartmentCode = "08000" | "10000" | "51000" | "52000" | "54000" | "55000" | "57000" | "67000" | "68000" | "88000"

export interface Department {
  code: DepartmentCode
  name: string
  sector: "Alsace" | "Champagne-Ardenne" | "Lorraine"
}

export interface Row {
  StructureId: number
  StructureCode: string
  StructureNom: string
  StructureNomCourt: string
  StructureCodeDepartement: DepartmentCode
  AdresseWeb: string | null
  AdresseFB: string | null
  AdresseInsta: string | null
}

export const departmentsCodes: DepartmentCode[] = ["08000", "10000", "51000", "52000", "67000", "68000", "54000", "55000", "57000", "88000"]

export const departments: { [key: string]: Department } = {
  "08000": {
    code: "08000",
    name: "Ardennes",
    sector: "Champagne-Ardenne",
  },
  "10000": {
    code: "10000",
    name: "Aube",
    sector: "Champagne-Ardenne",
  },
  "51000": {
    code: "51000",
    name: "Marne",
    sector: "Champagne-Ardenne",
  },
  "52000": {
    code: "52000",
    name: "Haute-Marne",
    sector: "Champagne-Ardenne",
  },
  "54000": {
    code: "54000",
    name: "Meurthe-et-Moselle",
    sector: "Lorraine",
  },
  "55000": {
    code: "55000",
    name: "Meuse",
    sector: "Lorraine",
  },
  "57000": {
    code: "57000",
    name: "Moselle",
    sector: "Lorraine",
  },
  "67000": {
    code: "67000",
    name: "Bas-Rhin",
    sector: "Alsace",
  },
  "68000": {
    code: "68000",
    name: "Haut-Rhin",
    sector: "Alsace",
  },
  "88000": {
    code: "88000",
    name: "Vosges",
    sector: "Lorraine",
  },
}

// SELECT
//   DISTINCT Structures.StructureId,
//   Structures.StructureCode,
//   Structures.StructureNom,
//   Structures.StructureNomCourt,
//   Structures.StructureCodeDepartement,
//   Adresses.AdresseWeb
// FROM Structures
// LEFT JOIN Adresses
// ON Adresses.StructureId = Structures.StructureId
// LEFT JOIN Affiliations
// ON Affiliations.StructureId = Structures.StructureId
//   AND (Affiliations.SaisonAnnee = 2021 OR Affiliations.SaisonAnnee = 2022)
//   AND Affiliations.EcrId != 0
// WHERE Structures.StructureEtat = 'A'
//   AND Structures.StructureCodeRegion = 'CR06'
//   AND Structures.StructureType = 'CLU'
//   AND Affiliations.AffilEtat = 'P'
// ORDER BY Structures.StructureCodeDepartement;

export const clubs: Row[] = [
  {
    StructureId: 3,
    StructureCode: "0608003",
    StructureNom: "COMPAGNIE CHARLES DE GONZAGUE",
    StructureNomCourt: "CHARLEVILLE MEZIERES",
    StructureCodeDepartement: "08000",
    AdresseWeb: "http://archersdegonzague.fr",
    AdresseFB: "https://www.facebook.com/archersdegonzague/",
    AdresseInsta: "https://instagram.com/archersdegonzague?igshid=OGQ5ZDc2ODk2ZA=="
  },
  {
    StructureId: 5,
    StructureCode: "0608006",
    StructureNom: "LES ARCHERS MOUZONNAIS",
    StructureNomCourt: "MOUZON",
    StructureCodeDepartement: "08000",
    AdresseWeb: "",
    AdresseFB: "https://www.facebook.com/duj08/",
    AdresseInsta: ""
  },
  {
    StructureId: 4,
    StructureCode: "0608005",
    StructureNom: "RETHEL SPORTIF TIR A L'ARC",
    StructureNomCourt: "RETHEL",
    StructureCodeDepartement: "08000",
    AdresseWeb: "",
    AdresseFB: "",
    AdresseInsta: ""
  },
  {
    StructureId: 2,
    StructureCode: "0608002",
    StructureNom: "LA FLECHE SEDANAISE",
    StructureNomCourt: "SEDAN",
    StructureCodeDepartement: "08000",
    AdresseWeb: "https://www.laflechesedanaise.fr",
    AdresseFB: "https://www.facebook.com/profile.php?id=100089804062797",
    AdresseInsta: ""
  },
  {
    StructureId: 1,
    StructureCode: "0608001",
    StructureNom: "TIR A L'ARC DEUX VIREUX",
    StructureNomCourt: "VIREUX-WALLERAND",
    StructureCodeDepartement: "08000",
    AdresseWeb: "",
    AdresseFB: "",
    AdresseInsta: ""
  },
  {
    StructureId: 6,
    StructureCode: "0608007",
    StructureNom: "LES ARCHERS VRIGNOIS",
    StructureNomCourt: "VRIGNE AUX BOIS",
    StructureCodeDepartement: "08000",
    AdresseWeb: "http://lesarchersvrignois.jimdo.com",
    AdresseFB: "",
    AdresseInsta: "https://www.instagram.com/les_archersvrignois"
  },
  {
    StructureId: 12,
    StructureCode: "0610018",
    StructureNom: "LES ARCHERS OTHEENS",
    StructureNomCourt: "AIX EN OTHE",
    StructureCodeDepartement: "10000",
    AdresseWeb: "https://les-archers-otheens.my.canva.site/",
    AdresseFB: "https://www.facebook.com/archersotheens",
    AdresseInsta: "https://www.instagram.com/les_archers_otheens"
  },
  {
    StructureId: 18,
    StructureCode: "0610028",
    StructureNom: "LES ARCHERS BRIENNOIS",
    StructureNomCourt: "BRIENNE LE CHÂTEAU",
    StructureCodeDepartement: "10000",
    AdresseWeb: "",
    AdresseFB: "https://www.facebook.com/archersbriennois",
    AdresseInsta: ""
  },
  {
    StructureId: 13,
    StructureCode: "0610019",
    StructureNom: "LES ARCHERS DES PRES DORES",
    StructureNomCourt: "ARCIS SUR AUBE",
    StructureCodeDepartement: "10000",
    AdresseWeb: "https://archers-de-pres-dores.clubeo.com/",
    AdresseFB: "",
    AdresseInsta: ""
  },
  {
    StructureId: 11,
    StructureCode: "0610017",
    StructureNom: "LES ARCHERS DU PEAGE",
    StructureNomCourt: "AUXON",
    StructureCodeDepartement: "10000",
    AdresseWeb: "",
    AdresseFB: "https://www.facebook.com/groups/1812290415694988/",
    AdresseInsta: ""
  },
  {
    StructureId: 9,
    StructureCode: "0610015",
    StructureNom: "FOYER DES JEUNES JEAN VILAR",
    StructureNomCourt: "BAR SUR SEINE",
    StructureCodeDepartement: "10000",
    AdresseWeb: "http://archers-bar-sur-seine.clubeo.com",
    AdresseFB: "",
    AdresseInsta: ""
  },
  {
    StructureId: 17,
    StructureCode: "0610025",
    StructureNom: "CIE D'ARC DU CHAOURCOIS",
    StructureNomCourt: "CHAOURCE",
    StructureCodeDepartement: "10000",
    AdresseWeb: "",
    AdresseFB: "",
    AdresseInsta: ""
  },
  {
    StructureId: 16,
    StructureCode: "0610024",
    StructureNom: "LES ARCHERS DE L'ETOILE",
    StructureNomCourt: "MAIZIERES GDE PAROISSE",
    StructureCodeDepartement: "10000",
    AdresseWeb: "",
    AdresseFB: "https://www.facebook.com/Les-archers-de-l-Etoile-de-Maizieres-la-grande-Paroisse-687343804613662/",
    AdresseInsta: ""
  },
  {
    StructureId: 15,
    StructureCode: "0610023",
    StructureNom: "ROMILLY SPORTS - 10",
    StructureNomCourt: "ROMILLY SUR SEINE",
    StructureCodeDepartement: "10000",
    AdresseWeb: "",
    AdresseFB: "",
    AdresseInsta: ""
  },
  {
    StructureId: 7,
    StructureCode: "0610009",
    StructureNom: "LES ARCHERS TROYENS",
    StructureNomCourt: "TROYES",
    StructureCodeDepartement: "10000",
    AdresseWeb: "https://lesarcherstroyens.sportsregions.fr/",
    AdresseFB: "https://www.facebook.com/archerstroyens",
    AdresseInsta: ""
  },
  {
    StructureId: 10,
    StructureCode: "0610016",
    StructureNom: "ARCHERS DU TEMPLE",
    StructureNomCourt: "VAUCHONVILLIERS",
    StructureCodeDepartement: "10000",
    AdresseWeb: "http://club.quomodo.com/lesarchersdutemple/",
    AdresseFB: "",
    AdresseInsta: ""
  },
  {
    StructureId: 8,
    StructureCode: "0610011",
    StructureNom: "ARCHERS VAL DE BARSE",
    StructureNomCourt: "VENDEUVRE SUR BARSE",
    StructureCodeDepartement: "10000",
    AdresseWeb: "https://www.archers-val-de-barse.fr/",
    AdresseFB: "https://www.facebook.com/Les-Archers-du-Val-de-Barse-101908893203030/",
    AdresseInsta: ""
  },
  {
    StructureId: 14,
    StructureCode: "0610022",
    StructureNom: "LES ARCHERS DE ST BERNARD",
    StructureNomCourt: "VILLE SOUS LA FERTE",
    StructureCodeDepartement: "10000",
    AdresseWeb: "",
    AdresseFB: "",
    AdresseInsta: ""
  },
  {
    StructureId: 22,
    StructureCode: "0651035",
    StructureNom: "SECTION TIR A L'ARC FJEP",
    StructureNomCourt: "BAZANCOURT",
    StructureCodeDepartement: "51000",
    AdresseWeb: "http://www.fjep-bazancourt.fr",
    AdresseFB: "",
    AdresseInsta: ""
  },
  {
    StructureId: 34,
    StructureCode: "0651213",
    StructureNom: "BEZANNES TIR A L'ARC",
    StructureNomCourt: "BEZANNES",
    StructureCodeDepartement: "51000",
    AdresseWeb: "https://arcbezannes.onlc.fr/",
    AdresseFB: "https://www.facebook.com/people/Bezannes-tir-%C3%A0-larc/100057529056441/",
    AdresseInsta: "https://www.instagram.com/bezannes.tiralarc/?igsh=Y29tMTE4OHo0ZXFq"
  },
  {
    StructureId: 23,
    StructureCode: "0651036",
    StructureNom: "LES ARCHERS CHALONNAIS",
    StructureNomCourt: "CHALONS EN CHAMPAGNE",
    StructureCodeDepartement: "51000",
    AdresseWeb: "http://www.lesarcherschalonnais.fr",
    AdresseFB: "",
    AdresseInsta: ""
  },
  {
    StructureId: 31,
    StructureCode: "0651051",
    StructureNom: "1ERE COMPAGNIE D ARC DE CHALONS EN CHAMP",
    StructureNomCourt: "CHALONS EN CHAMPAGNE",
    StructureCodeDepartement: "51000",
    AdresseWeb: "http://www.arc-chalons.fr/",
    AdresseFB: "https://www.facebook.com/profile.php?id=100010984392737",
    AdresseInsta: ""
  },
  {
    StructureId: 19,
    StructureCode: "0651030",
    StructureNom: "CLUB EPERNAY DE TIR A L'ARC",
    StructureNomCourt: "EPERNAY",
    StructureCodeDepartement: "51000",
    AdresseWeb: "https://ceptiralarcepernay.sportsregions.fr/",
    AdresseFB: "",
    AdresseInsta: ""
  },
  {
    StructureId: 25,
    StructureCode: "0651039",
    StructureNom: "CIE D ARC DE FISMES",
    StructureNomCourt: "FISMES",
    StructureCodeDepartement: "51000",
    AdresseWeb: "http://arc-fismes.com",
    AdresseFB: "",
    AdresseInsta: ""
  },
  {
    StructureId: 27,
    StructureCode: "0651041",
    StructureNom: "ARCHERIE DES GOTHS",
    StructureNomCourt: "GUEUX",
    StructureCodeDepartement: "51000",
    AdresseWeb: "",
    AdresseFB: "",
    AdresseInsta: ""
  },
  {
    StructureId: 32,
    StructureCode: "0651052",
    StructureNom: "LES ARCHERS DE MAREUIL SUR AY",
    StructureNomCourt: "MAREUIL SUR AY",
    StructureCodeDepartement: "51000",
    AdresseWeb: "",
    AdresseFB: "https://www.facebook.com/profile.php?id=100083014586328",
    AdresseInsta: ""
  },
  {
    StructureId: 26,
    StructureCode: "0651040",
    StructureNom: "CLUB SP.GAR.MOURMELON",
    StructureNomCourt: "MOURMELON LE GRAND",
    StructureCodeDepartement: "51000",
    AdresseWeb: "",
    AdresseFB: "",
    AdresseInsta: ""
  },
  {
    StructureId: 33,
    StructureCode: "0651209",
    StructureNom: "ARC CLUB PARGNYSIEN",
    StructureNomCourt: "PARGNY SUR SAULX",
    StructureCodeDepartement: "51000",
    AdresseWeb: "",
    AdresseFB: "",
    AdresseInsta: ""
  },
  {
    StructureId: 20,
    StructureCode: "0651031",
    StructureNom: "CIE D'ARC DE REIMS",
    StructureNomCourt: "REIMS",
    StructureCodeDepartement: "51000",
    AdresseWeb: "http://www.compagnie-arc-reims.com/index.html",
    AdresseFB: "https://www.facebook.com/home.php?",
    AdresseInsta: ""
  },
  {
    StructureId: 21,
    StructureCode: "0651034",
    StructureNom: "COMPAGNIE D'ARC DES VALLONS D'ARGONNE",
    StructureNomCourt: "STE MENEHOULD",
    StructureCodeDepartement: "51000",
    AdresseWeb: "",
    AdresseFB: "https://www.facebook.com/La-Compagnie-dArc-des-Vallons-dArgonne-626233080910324/",
    AdresseInsta: ""
  },
  {
    StructureId: 30,
    StructureCode: "0651050",
    StructureNom: "LES ARCHERS DE LA SUIPPE",
    StructureNomCourt: "SUIPPES",
    StructureCodeDepartement: "51000",
    AdresseWeb: "http://les-archers-de-la-suippe.e-monsite.com",
    AdresseFB: "https://www.facebook.com/arcsuipes",
    AdresseInsta: ""
  },
  {
    StructureId: 29,
    StructureCode: "0651048",
    StructureNom: "STR",
    StructureNomCourt: "TINQUEUX",
    StructureCodeDepartement: "51000",
    AdresseWeb: "http://www.tirdereims.com",
    AdresseFB: "",
    AdresseInsta: ""
  },
  {
    StructureId: 24,
    StructureCode: "0651038",
    StructureNom: "CIE DES ARCHERS VERTUSIENS",
    StructureNomCourt: "VERTUS",
    StructureCodeDepartement: "51000",
    AdresseWeb: "http://club.sportsregions.fr/archers-vertusiens/",
    AdresseFB: "",
    AdresseInsta: ""
  },
  {
    StructureId: 28,
    StructureCode: "0651047",
    StructureNom: "ARCHERY DES LOUPS",
    StructureNomCourt: "VERZY",
    StructureCodeDepartement: "51000",
    AdresseWeb: "http://archerydesloups.e-monsite.com/",
    AdresseFB: "https://www.facebook.com/archerydesloups.verzy",
    AdresseInsta: ""
  },
  {
    StructureId: 39,
    StructureCode: "0652061",
    StructureNom: "S.L.O. TIR A L'ARC",
    StructureNomCourt: "BETTANCOURT LA FERREE",
    StructureCodeDepartement: "52000",
    AdresseWeb: "",
    AdresseFB: "https://www.facebook.com/Slobettancourt/",
    AdresseInsta: ""
  },
  {
    StructureId: 36,
    StructureCode: "0652056",
    StructureNom: "LES ARCHERS DES SOURCES",
    StructureNomCourt: "BOURBONNE LES BAINS",
    StructureCodeDepartement: "52000",
    AdresseWeb: "http://les-archers-des-sources.fr",
    AdresseFB: "",
    AdresseInsta: ""
  },
  {
    StructureId: 38,
    StructureCode: "0652060",
    StructureNom: "CLUB DES CASTORS BLEUS",
    StructureNomCourt: "CASTORS BLEUS",
    StructureCodeDepartement: "52000",
    AdresseWeb: "",
    AdresseFB: "",
    AdresseInsta: ""
  },
  {
    StructureId: 37,
    StructureCode: "0652058",
    StructureNom: "1 ERE COMPAGNIE DE CHAUMONT",
    StructureNomCourt: "CHAUMONT",
    StructureCodeDepartement: "52000",
    AdresseWeb: "https://archers-de-chaumont.clubeo.com/",
    AdresseFB: "https://www.facebook.com/ciedarcdechaumont/",
    AdresseInsta: ""
  },
  {
    StructureId: 35,
    StructureCode: "0652055",
    StructureNom: "1ERE COMPAGNIE D'ARC ECLARON",
    StructureNomCourt: "ECLARON",
    StructureCodeDepartement: "52000",
    AdresseWeb: "https://arc-eclaron.sportsregions.fr/",
    AdresseFB: "",
    AdresseInsta: ""
  },
  {
    StructureId: 40,
    StructureCode: "0652062",
    StructureNom: "LA FLECHE BRAGARDE",
    StructureNomCourt: "SAINT DIZIER",
    StructureCodeDepartement: "52000",
    AdresseWeb: "http://laflechebragarde.e-monsite.com",
    AdresseFB: "",
    AdresseInsta: ""
  },
  {
    StructureId: 56,
    StructureCode: "0654088",
    StructureNom: "LA CHAMPIGNEULLAISE",
    StructureNomCourt: "CHAMPIGNEULLES",
    StructureCodeDepartement: "54000",
    AdresseWeb: "http://www.champigneullaise.fr",
    AdresseFB: "",
    AdresseInsta: ""
  },
  {
    StructureId: 41,
    StructureCode: "0654064",
    StructureNom: "CIE D'ARC DE CONFLANS EN JARNISY",
    StructureNomCourt: "CONFLANS EN JARNISY",
    StructureCodeDepartement: "54000",
    AdresseWeb: "http://ctacj.free.fr",
    AdresseFB: "",
    AdresseInsta: ""
  },
  {
    StructureId: 52,
    StructureCode: "0654081",
    StructureNom: "CIE TIR A L'ARC DE DOMBASLE",
    StructureNomCourt: "DOMBASLE SUR MEURTHE",
    StructureCodeDepartement: "54000",
    AdresseWeb: "http://www.arc-dombasle.com/",
    AdresseFB: "https://www.facebook.com/1%C3%A8re-Compagnie-de-tir-%C3%A0-larc-de-Dombasle-sur-meurthe-585421258198883/",
    AdresseInsta: ""
  },
  {
    StructureId: 55,
    StructureCode: "0654086",
    StructureNom: "LES ARCHERS DU SANON",
    StructureNomCourt: "EINVILLE",
    StructureCodeDepartement: "54000",
    AdresseWeb: "https://sites.google.com/site/lesarchersdusanon/",
    AdresseFB: "",
    AdresseInsta: ""
  },
  {
    StructureId: 42,
    StructureCode: "0654066",
    StructureNom: "O.F.P. SECTION TIR A L'ARC",
    StructureNomCourt: "FROUARD",
    StructureCodeDepartement: "54000",
    AdresseWeb: "http://www.ofptiralarc.fr/topic/index.html",
    AdresseFB: "https://www.facebook.com/OFPTiralArc",
    AdresseInsta: ""
  },
  {
    StructureId: 45,
    StructureCode: "0654070",
    StructureNom: "ARCHER CLUB HAUCOURT-MOULAINE",
    StructureNomCourt: "HAUCOURT-MOULAINE",
    StructureCodeDepartement: "54000",
    AdresseWeb: "http://archers-haucourt.fr/wordpress/",
    AdresseFB: "https://www.facebook.com/groups/624528117940610/",
    AdresseInsta: ""
  },
  {
    StructureId: 60,
    StructureCode: "0654212",
    StructureNom: "LES ARCHERS SANS LIMITE",
    StructureNomCourt: "HEILLECOURT",
    StructureCodeDepartement: "54000",
    AdresseWeb: "",
    AdresseFB: "",
    AdresseInsta: ""
  },
  {
    StructureId: 51,
    StructureCode: "0654080",
    StructureNom: "LA FLECHE HOMECOURTOISE",
    StructureNomCourt: "HOMECOURT",
    StructureCodeDepartement: "54000",
    AdresseWeb: "",
    AdresseFB: "",
    AdresseInsta: ""
  },
  {
    StructureId: 49,
    StructureCode: "0654078",
    StructureNom: "LA FLECHE D'AFFRIQUE LUDREENNE",
    StructureNomCourt: "LUDRES",
    StructureCodeDepartement: "54000",
    AdresseWeb: "http://www.laflechedaffrique.fr",
    AdresseFB: "https://www.facebook.com/people/Fl%C3%A8che-dAffrique-Ludres-54710/100064568270937/?ref=py_c",
    AdresseInsta: ""
  },
  {
    StructureId: 48,
    StructureCode: "0654077",
    StructureNom: "1ÈRE COMPAGNIE D'ARC DE LUNÉVILLE",
    StructureNomCourt: "LUNEVILLE",
    StructureCodeDepartement: "54000",
    AdresseWeb: "",
    AdresseFB: "https://www.facebook.com/lesarcherslunevillois/",
    AdresseInsta: ""
  },
  {
    StructureId: 43,
    StructureCode: "0654067",
    StructureNom: "LES FLECHES ROYALES",
    StructureNomCourt: "MONT L'ETROIT",
    StructureCodeDepartement: "54000",
    AdresseWeb: "https://flechesroyales.wixsite.com/website",
    AdresseFB: "",
    AdresseInsta: ""
  },
  {
    StructureId: 47,
    StructureCode: "0654073",
    StructureNom: "1ERE CIE DE TIR A L'ARC DE NANCY",
    StructureNomCourt: "NANCY",
    StructureCodeDepartement: "54000",
    AdresseWeb: "http://www.tiralarcnancy.fr/",
    AdresseFB: "https://www.facebook.com/tiralarcnancy/?ref=bookmarks",
    AdresseInsta: ""
  },
  {
    StructureId: 59,
    StructureCode: "0654091",
    StructureNom: "COMPAGNIE DE NEUVES MAISONS",
    StructureNomCourt: "NEUVES MAISONS",
    StructureCodeDepartement: "54000",
    AdresseWeb: "",
    AdresseFB: "",
    AdresseInsta: ""
  },
  {
    StructureId: 57,
    StructureCode: "0654089",
    StructureNom: "CLUB ARCHERS MUSSIPONTAINS",
    StructureNomCourt: "PONT A MOUSSON",
    StructureCodeDepartement: "54000",
    AdresseWeb: "https://www.campam54.com/",
    AdresseFB: "https://www.facebook.com/Campam54/",
    AdresseInsta: ""
  },
  {
    StructureId: 58,
    StructureCode: "0654090",
    StructureNom: "PONT SAINT VINCENT",
    StructureNomCourt: "PONT ST VINCENT",
    StructureCodeDepartement: "54000",
    AdresseWeb: "http://www.ciearcpontstvincent.fr/",
    AdresseFB: "",
    AdresseInsta: ""
  },
  {
    StructureId: 53,
    StructureCode: "0654083",
    StructureNom: "CIE D'ARC DE SEICHAMPS",
    StructureNomCourt: "SEICHAMPS",
    StructureCodeDepartement: "54000",
    AdresseWeb: "https://arcseichamps.sportsregions.fr",
    AdresseFB: "https://www.facebook.com/Compagnie-dArc-de-Seichamps-1724730667546883/",
    AdresseInsta: ""
  },
  {
    StructureId: 46,
    StructureCode: "0654071",
    StructureNom: "1ERE COMPAGNIE DES ARCHERS",
    StructureNomCourt: "ST NICOLAS DE PORT",
    StructureCodeDepartement: "54000",
    AdresseWeb: "https://www.archers-portois.com/",
    AdresseFB: "",
    AdresseInsta: ""
  },
  {
    StructureId: 50,
    StructureCode: "0654079",
    StructureNom: "1ERE CIE D'ARC DU TOULOIS",
    StructureNomCourt: "TOUL",
    StructureCodeDepartement: "54000",
    AdresseWeb: "https://www.arctoulois.fr/",
    AdresseFB: "",
    AdresseInsta: ""
  },
  {
    StructureId: 54,
    StructureCode: "0654084",
    StructureNom: "1 CIE VANDOEUVRE",
    StructureNomCourt: "VANDOEUVRE",
    StructureCodeDepartement: "54000",
    AdresseWeb: "https://www.archersvandoeuvre.fr/",
    AdresseFB: "",
    AdresseInsta: ""
  },
  {
    StructureId: 44,
    StructureCode: "0654069",
    StructureNom: "CLUB DES ARCHERS DE VARANGEVILLE",
    StructureNomCourt: "VARANGEVILLE",
    StructureCodeDepartement: "54000",
    AdresseWeb: "http://sites.google.com/site/archersdevarangeville/",
    AdresseFB: "https://www.facebook.com/archers.de.varangeville",
    AdresseInsta: ""
  },
  {
    StructureId: 61,
    StructureCode: "0655093",
    StructureNom: "LES FLECHES BARISIENNES",
    StructureNomCourt: "BAR LE DUC",
    StructureCodeDepartement: "55000",
    AdresseWeb: "",
    AdresseFB: "",
    AdresseInsta: ""
  },
  {
    StructureId: 68,
    StructureCode: "0655103",
    StructureNom: "ARC CLUB BOULIGNY",
    StructureNomCourt: "BOULIGNY",
    StructureCodeDepartement: "55000",
    AdresseWeb: "",
    AdresseFB: "https://www.facebook.com/groups/605618360627665",
    AdresseInsta: ""
  },
  {
    StructureId: 64,
    StructureCode: "0655098",
    StructureNom: "CIE D'ARC DES SEIGNEURS DE BREUX",
    StructureNomCourt: "BREUX",
    StructureCodeDepartement: "55000",
    AdresseWeb: "http://www.arcbreux.fr",
    AdresseFB: "",
    AdresseInsta: ""
  },
  {
    StructureId: 66,
    StructureCode: "0655101",
    StructureNom: "LES ARCHERS COMMERCIENS",
    StructureNomCourt: "COMMERCY",
    StructureCodeDepartement: "55000",
    AdresseWeb: "https://archerscommerciens.e-monsite.com/",
    AdresseFB: "https://www.facebook.com/Les-Archers-Commerciens-169822140090732",
    AdresseInsta: "https://www.instagram.com/lesarcherscommerciens/"
  },
  {
    StructureId: 67,
    StructureCode: "0655102",
    StructureNom: "L.S.G TIR A L'ARC",
    StructureNomCourt: "LIGNY EN BARROIS",
    StructureCodeDepartement: "55000",
    AdresseWeb: "",
    AdresseFB: "",
    AdresseInsta: ""
  },
  {
    StructureId: 62,
    StructureCode: "0655096",
    StructureNom: "LES FRANCS ARCHERS DU VERDUNOIS",
    StructureNomCourt: "VERDUN",
    StructureCodeDepartement: "55000",
    AdresseWeb: "https://www.facebook.com/people/Francs-Archers-Verdunois/100083169280476/",
    AdresseFB: "https://www.facebook.com/Francs-Archers-du-Verdunois-612059448983645/",
    AdresseInsta: ""
  },
  {
    StructureId: 63,
    StructureCode: "0655097",
    StructureNom: "LES ARCHERS DES COTES DE MEUSE",
    StructureNomCourt: "VIGNEULLES LES HATTONCHATEL",
    StructureCodeDepartement: "55000",
    AdresseWeb: "",
    AdresseFB: "",
    AdresseInsta: ""
  },
  {
    StructureId: 65,
    StructureCode: "0655100",
    StructureNom: "L'ARC VIDUSIEN",
    StructureNomCourt: "VOID VACON",
    StructureCodeDepartement: "55000",
    AdresseWeb: "",
    AdresseFB: "",
    AdresseInsta: ""
  },
  {
    StructureId: 77,
    StructureCode: "0657121",
    StructureNom: "MAISON DES JEUNES ET DE LA CULTURE D'AMANVILLERS",
    StructureNomCourt: "AMANVILLERS",
    StructureCodeDepartement: "57000",
    AdresseWeb: "",
    AdresseFB: "",
    AdresseInsta: ""
  },
  {
    StructureId: 83,
    StructureCode: "0657130",
    StructureNom: "ARS SUR MOSELLE OLYMPIQUE",
    StructureNomCourt: "ARS SUR MOSELLE",
    StructureCodeDepartement: "57000",
    AdresseWeb: "https://www.amotiralarc.fr/",
    AdresseFB: "https://www.facebook.com/arssurmoselleolympique/",
    AdresseInsta: ""
  },
  {
    StructureId: 74,
    StructureCode: "0657114",
    StructureNom: "LA FLECHE BITCHOISE",
    StructureNomCourt: "BITCHE",
    StructureCodeDepartement: "57000",
    AdresseWeb: "",
    AdresseFB: "",
    AdresseInsta: ""
  },
  {
    StructureId: 79,
    StructureCode: "0657123",
    StructureNom: "CIE ARCHERS BOUZONVILLE",
    StructureNomCourt: "BOUZONVILLE",
    StructureCodeDepartement: "57000",
    AdresseWeb: "http://archers-bouzonville.club.sportsregions.fr",
    AdresseFB: "https://www.facebook.com/COMPAGNIE-DES-ARCHERS-DE-BOUZONVILLE-181138338598869",
    AdresseInsta: ""
  },
  {
    StructureId: 88,
    StructureCode: "0657137",
    StructureNom: "LES ARCHERS DE CREUTZWALD",
    StructureNomCourt: "CREUTZWALD",
    StructureCodeDepartement: "57000",
    AdresseWeb: "https://www.archer-creutzwald.fr",
    AdresseFB: "https://www.facebook.com/archerscreutzwald",
    AdresseInsta: ""
  },
  {
    StructureId: 73,
    StructureCode: "0657111",
    StructureNom: "SOCIETE DE TIR",
    StructureNomCourt: "DIEUZE",
    StructureCodeDepartement: "57000",
    AdresseWeb: "",
    AdresseFB: "",
    AdresseInsta: ""
  },
  {
    StructureId: 76,
    StructureCode: "0657119",
    StructureNom: "FAREBERSVILLER",
    StructureNomCourt: "FAREBERSVILLER",
    StructureCodeDepartement: "57000",
    AdresseWeb: "",
    AdresseFB: "",
    AdresseInsta: ""
  },
  {
    StructureId: 80,
    StructureCode: "0657124",
    StructureNom: "L'ARC FAULQUINOIS",
    StructureNomCourt: "FAULQUEMONT",
    StructureCodeDepartement: "57000",
    AdresseWeb: "https://arc-faulquinois.fr",
    AdresseFB: "https://www.facebook.com/arc.faulquinois",
    AdresseInsta: ""
  },
  {
    StructureId: 85,
    StructureCode: "0657132",
    StructureNom: "CIE ARC FOLSCHVILLER",
    StructureNomCourt: "FOLSCHVILLER",
    StructureCodeDepartement: "57000",
    AdresseWeb: "https://www.archers-folschviller.free-h.net",
    AdresseFB: "",
    AdresseInsta: ""
  },
  {
    StructureId: 75,
    StructureCode: "0657116",
    StructureNom: "ARCHERS GORZIENS",
    StructureNomCourt: "GORZE",
    StructureCodeDepartement: "57000",
    AdresseWeb: "http://www.archer-gorzien.com",
    AdresseFB: "https://www.facebook.com/larcher.gorzien",
    AdresseInsta: ""
  },
  {
    StructureId: 70,
    StructureCode: "0657106",
    StructureNom: "ENTENTE SPORTIVE HAGONDANGE",
    StructureNomCourt: "HAGONDANGE",
    StructureCodeDepartement: "57000",
    AdresseWeb: "http://club.sportsregions.fr/lesarchersdelesh",
    AdresseFB: "",
    AdresseInsta: ""
  },
  {
    StructureId: 72,
    StructureCode: "0657110",
    StructureNom: "1ERE COMPAGNIE D'ARC HAYANGE",
    StructureNomCourt: "HAYANGE",
    StructureCodeDepartement: "57000",
    AdresseWeb: "http://archershayangeois.jimdo.com/",
    AdresseFB: "https://www.facebook.com/1ereCieArcHayange/",
    AdresseInsta: ""
  },
  {
    StructureId: 81,
    StructureCode: "0657125",
    StructureNom: "LES ATHABASCANS",
    StructureNomCourt: "HETTANGE GRANDE",
    StructureCodeDepartement: "57000",
    AdresseWeb: "http://www.lesathabascans.org",
    AdresseFB: "",
    AdresseInsta: ""
  },
  {
    StructureId: 86,
    StructureCode: "0657134",
    StructureNom: "LES ARCHERS DE MARANGE SILVANGE",
    StructureNomCourt: "MARANGE SILVANGE",
    StructureCodeDepartement: "57000",
    AdresseWeb: "https://archers57535.sportsregions.fr",
    AdresseFB: "",
    AdresseInsta: ""
  },
  {
    StructureId: 69,
    StructureCode: "0657105",
    StructureNom: "LES ARCHERS DE MARLY-POURNOY LA CHETIVE",
    StructureNomCourt: "MARLY POURNOY",
    StructureCodeDepartement: "57000",
    AdresseWeb: "http://www.archersmarlypournoy.com",
    AdresseFB: "https://www.facebook.com/archers.de.marly.pournoy/",
    AdresseInsta: ""
  },
  {
    StructureId: 71,
    StructureCode: "0657108",
    StructureNom: "LES ARCHERS DU ST QUENTIN ASC METZ",
    StructureNomCourt: "METZ",
    StructureCodeDepartement: "57000",
    AdresseWeb: "http://www.lesarchersdustquentin.fr/",
    AdresseFB: "",
    AdresseInsta: ""
  },
  {
    StructureId: 82,
    StructureCode: "0657126",
    StructureNom: "MONTIGNY LES METZ",
    StructureNomCourt: "MONTIGNY-LÈS-METZ",
    StructureCodeDepartement: "57000",
    AdresseWeb: "https://www.archer-montigny-les-metz.com/",
    AdresseFB: "",
    AdresseInsta: ""
  },
  {
    StructureId: 90,
    StructureCode: "0657210",
    StructureNom: "LES ARCHERS DE MOYEUVRE-GRANDE",
    StructureNomCourt: "MOYEUVRE-GRANDE",
    StructureCodeDepartement: "57000",
    AdresseWeb: "",
    AdresseFB: "",
    AdresseInsta: ""
  },
  {
    StructureId: 84,
    StructureCode: "0657131",
    StructureNom: "PREMIERE COMPAGNIE D'ARC DE SARREBOURG",
    StructureNomCourt: "SARREBOURG",
    StructureCodeDepartement: "57000",
    AdresseWeb: "http://tiralarc.sarrebourg.free.fr",
    AdresseFB: "",
    AdresseInsta: ""
  },
  {
    StructureId: 89,
    StructureCode: "0657140",
    StructureNom: "1ERE CIE DE TIR A L'ARC",
    StructureNomCourt: "SARREGUEMINES",
    StructureCodeDepartement: "57000",
    AdresseWeb: "",
    AdresseFB: "",
    AdresseInsta: ""
  },
  {
    StructureId: 78,
    StructureCode: "0657122",
    StructureNom: "CIE DES ARCHERS DE STIRING-WENDEL",
    StructureNomCourt: "STIRING-WENDEL",
    StructureCodeDepartement: "57000",
    AdresseWeb: "http://www.lesrobinsstiringeois.fr",
    AdresseFB: "",
    AdresseInsta: ""
  },
  {
    StructureId: 87,
    StructureCode: "0657135",
    StructureNom: "1 CTA DE THIONVILLE",
    StructureNomCourt: "THIONVILLE",
    StructureCodeDepartement: "57000",
    AdresseWeb: "https://1erecta57.sportsregions.fr/",
    AdresseFB: "",
    AdresseInsta: ""
  },
  {
    StructureId: 99,
    StructureCode: "0667158",
    StructureNom: "FOYER CLUB JEUNES DU ZORNTHAL",
    StructureNomCourt: "BRUMATH",
    StructureCodeDepartement: "67000",
    AdresseWeb: "",
    AdresseFB: "https://www.facebook.com/Archers-de-Brumath-160757413957141/?fref=ts",
    AdresseInsta: ""
  },
  {
    StructureId: 94,
    StructureCode: "0667150",
    StructureNom: "A.S.O.R. ERSTEIN",
    StructureNomCourt: "ERSTEIN",
    StructureCodeDepartement: "67000",
    AdresseWeb: "",
    AdresseFB: "",
    AdresseInsta: ""
  },
  {
    StructureId: 92,
    StructureCode: "0667144",
    StructureNom: "ARCHERS DE HAGUENAU",
    StructureNomCourt: "HAGUENAU",
    StructureCodeDepartement: "67000",
    AdresseWeb: "https://www.archers-haguenau.fr",
    AdresseFB: "",
    AdresseInsta: ""
  },
  {
    StructureId: 95,
    StructureCode: "0667151",
    StructureNom: "CIE D'ARCHERS D'HERRLISHEIM",
    StructureNomCourt: "HERRLISHEIM",
    StructureCodeDepartement: "67000",
    AdresseWeb: "http://archersherrlisheim.free.fr/",
    AdresseFB: "https://www.facebook.com/Compagnie-dArchers-de-Herrlisheim-67-1606141172991358/",
    AdresseInsta: ""
  },
  {
    StructureId: 96,
    StructureCode: "0667154",
    StructureNom: "ASSOCIATION DE TIR A L'ARC ET LOISIRS",
    StructureNomCourt: "ILLKIRCH GRAFFENSTADEN",
    StructureCodeDepartement: "67000",
    AdresseWeb: "http://www.arc-comp-loisir.net",
    AdresseFB: "",
    AdresseInsta: ""
  },
  {
    StructureId: 91,
    StructureCode: "0667143",
    StructureNom: "COMPAGNONS D'ARC LINGOLSHEIM",
    StructureNomCourt: "LINGOLSHEIM",
    StructureCodeDepartement: "67000",
    AdresseWeb: "http://www.archers-lingolsheim.fr",
    AdresseFB: "",
    AdresseInsta: ""
  },
  {
    StructureId: 98,
    StructureCode: "0667157",
    StructureNom: "CIE D'ARCHERS DU PAYS DE HANAU",
    StructureNomCourt: "NEUWILLER LES SAVERNE",
    StructureCodeDepartement: "67000",
    AdresseWeb: "http://www.archersdupaysdehanau.fr/",
    AdresseFB: "",
    AdresseInsta: ""
  },
  {
    StructureId: 93,
    StructureCode: "0667147",
    StructureNom: "LES ARCHERS DE LA HAUTE EHN",
    StructureNomCourt: "OBERNAI",
    StructureCodeDepartement: "67000",
    AdresseWeb: "https://www.ahe-obernai.fr",
    AdresseFB: "https://www.facebook.com/ahe.obernai/",
    AdresseInsta: ""
  },
  {
    StructureId: 100,
    StructureCode: "0667159",
    StructureNom: "LES ARCHERS DE SELESTAT",
    StructureNomCourt: "SELESTAT",
    StructureCodeDepartement: "67000",
    AdresseWeb: "http://sites.google.com/site/archersselestat/",
    AdresseFB: "",
    AdresseInsta: ""
  },
  {
    StructureId: 101,
    StructureCode: "0667160",
    StructureNom: "1 CIE ARC STRASBOURG",
    StructureNomCourt: "STRASBOURG",
    StructureCodeDepartement: "67000",
    AdresseWeb: "http://www.arc-strasbourg.net",
    AdresseFB: "https://www.facebook.com/1ereciearcstrasbourg",
    AdresseInsta: ""
  },
  {
    StructureId: 97,
    StructureCode: "0667155",
    StructureNom: "AMICALE DE TIR SAINT HUBERT",
    StructureNomCourt: "WISCHES",
    StructureCodeDepartement: "67000",
    AdresseWeb: "",
    AdresseFB: "",
    AdresseInsta: ""
  },
  {
    StructureId: 102,
    StructureCode: "0667163",
    StructureNom: "CIE ARCHERS DE WISSEMBOURG",
    StructureNomCourt: "WISSEMBOURG",
    StructureCodeDepartement: "67000",
    AdresseWeb: "http://archerswissembourg67.skyrock.com",
    AdresseFB: "",
    AdresseInsta: ""
  },
  {
    StructureId: 118,
    StructureCode: "0668215",
    StructureNom: "LES ARCHERS DES 3 FRONTIÈRES",
    StructureNomCourt: "A3F",
    StructureCodeDepartement: "68000",
    AdresseWeb: "",
    AdresseFB: "",
    AdresseInsta: ""
  },
  {
    StructureId: 105,
    StructureCode: "0668169",
    StructureNom: "ARCHERS ST GEORGES BRUNSTATT",
    StructureNomCourt: "BRUNSTATT",
    StructureCodeDepartement: "68000",
    AdresseWeb: "http://www.asg-brunstatt.fr",
    AdresseFB: "",
    AdresseInsta: ""
  },
  {
    StructureId: 110,
    StructureCode: "0668179",
    StructureNom: "COMPAGNONS DU HOHLANDSBOURG",
    StructureNomCourt: "COLMAR",
    StructureCodeDepartement: "68000",
    AdresseWeb: "https://les-compagnons-du-hohlandsbourg.assoconnect.com/",
    AdresseFB: "https://www.facebook.com/cdhcolmar/",
    AdresseInsta: ""
  },
  {
    StructureId: 117,
    StructureCode: "0668214",
    StructureNom: "LES ROBIN DE LA DOLLER",
    StructureNomCourt: "KIRCHBERG",
    StructureCodeDepartement: "68000",
    AdresseWeb: "",
    AdresseFB: "",
    AdresseInsta: ""
  },
  {
    StructureId: 104,
    StructureCode: "0668168",
    StructureNom: "LES ARCHERS DU FRENZ",
    StructureNomCourt: "KRUTH",
    StructureCodeDepartement: "68000",
    AdresseWeb: "",
    AdresseFB: "",
    AdresseInsta: ""
  },
  {
    StructureId: 113,
    StructureCode: "0668187",
    StructureNom: "MULHOUSE C.A.B.",
    StructureNomCourt: "MULHOUSE CAB",
    StructureCodeDepartement: "68000",
    AdresseWeb: "http://www.cabmulhouse.com",
    AdresseFB: "https://www.facebook.com/cabmulhouse/",
    AdresseInsta: "https://www.instagram.com/cab_mulhouse/"
  },
  {
    StructureId: 112,
    StructureCode: "0668183",
    StructureNom: "SOCIETE DE TIR A L ARC MULHOUSE",
    StructureNomCourt: "MULHOUSE STAM",
    StructureCodeDepartement: "68000",
    AdresseWeb: "https://stam-mulhouse.fr",
    AdresseFB: "https://www.facebook.com/stammulhouse",
    AdresseInsta: ""
  },
  {
    StructureId: 109,
    StructureCode: "0668176",
    StructureNom: "LES ARCHERS DE LA VALLEE DE MUNSTER",
    StructureNomCourt: "MUNSTER",
    StructureCodeDepartement: "68000",
    AdresseWeb: "",
    AdresseFB: "https://www.facebook.com/groups/842526259131810/",
    AdresseInsta: ""
  },
  {
    StructureId: 103,
    StructureCode: "0668167",
    StructureNom: "ARCHERS DU CERCLE RIXHEIM",
    StructureNomCourt: "RIXHEIM",
    StructureCodeDepartement: "68000",
    AdresseWeb: "http://www.archers-rixheim.fr",
    AdresseFB: "http://www.facebook.com/archersducerclerixheim/",
    AdresseInsta: ""
  },
  {
    StructureId: 116,
    StructureCode: "0668211",
    StructureNom: "ARCHERS DE LA ROSERAIE",
    StructureNomCourt: "ROSERAIE",
    StructureCodeDepartement: "68000",
    AdresseWeb: "",
    AdresseFB: "",
    AdresseInsta: ""
  },
  {
    StructureId: 108,
    StructureCode: "0668175",
    StructureNom: "LES ARCHERS DU FLORIVAL",
    StructureNomCourt: "SOULTZ",
    StructureCodeDepartement: "68000",
    AdresseWeb: "http://www.mjc-soultz.fr",
    AdresseFB: "",
    AdresseInsta: ""
  },
  {
    StructureId: 107,
    StructureCode: "0668173",
    StructureNom: "ARC CLUB STE CX PLAINE",
    StructureNomCourt: "STE CROIX EN PLAINE",
    StructureCodeDepartement: "68000",
    AdresseWeb: "http://arc-club-ste-croix.asso-web.com",
    AdresseFB: "",
    AdresseInsta: ""
  },
  {
    StructureId: 115,
    StructureCode: "0668191",
    StructureNom: "COMPAGNIE D'ARC STE CROIX EN PLAINE",
    StructureNomCourt: "STE CROIX EN PLAINE",
    StructureCodeDepartement: "68000",
    AdresseWeb: "http://www.compagniedarc.fr",
    AdresseFB: "",
    AdresseInsta: ""
  },
  {
    StructureId: 106,
    StructureCode: "0668171",
    StructureNom: "LES ARCHERS DE LA THUR",
    StructureNomCourt: "THANN",
    StructureCodeDepartement: "68000",
    AdresseWeb: "http://www.archersdelathur.org",
    AdresseFB: "https://fr-fr.facebook.com/ArchersDeLaThur",
    AdresseInsta: ""
  },
  {
    StructureId: 114,
    StructureCode: "0668190",
    StructureNom: "LES ARCHERS DE VOLGELSHEIM",
    StructureNomCourt: "VOLGELSHEIM",
    StructureCodeDepartement: "68000",
    AdresseWeb: "",
    AdresseFB: "",
    AdresseInsta: ""
  },
  {
    StructureId: 111,
    StructureCode: "0668180",
    StructureNom: "CIE D'ISHI ARCHERS DE WITTELSHEIM",
    StructureNomCourt: "WITTELSHEIM",
    StructureCodeDepartement: "68000",
    AdresseWeb: "http://www.compagnie-ishi.com",
    AdresseFB: "",
    AdresseInsta: ""
  },
  {
    StructureId: 120,
    StructureCode: "0688196",
    StructureNom: "CERCLE DES ARCHERS",
    StructureNomCourt: "CHARMES",
    StructureCodeDepartement: "88000",
    AdresseWeb: "",
    AdresseFB: "",
    AdresseInsta: ""
  },
  {
    StructureId: 124,
    StructureCode: "0688204",
    StructureNom: "1ERE CIE D'ARCHERS D'EPINAL",
    StructureNomCourt: "EPINAL",
    StructureCodeDepartement: "88000",
    AdresseWeb: "http://arc-epinal.fr",
    AdresseFB: "",
    AdresseInsta: ""
  },
  {
    StructureId: 125,
    StructureCode: "0688205",
    StructureNom: "CERCLE DES ARTS MARTIAUX",
    StructureNomCourt: "LE VAL D'AJOL",
    StructureCodeDepartement: "88000",
    AdresseWeb: "",
    AdresseFB: "",
    AdresseInsta: ""
  },
  {
    StructureId: 129,
    StructureCode: "0688209",
    StructureNom: "LES ARCHERS DE SENONES",
    StructureNomCourt: "LES ARCHERS DE SENONES",
    StructureCodeDepartement: "88000",
    AdresseWeb: "",
    AdresseFB: "",
    AdresseInsta: ""
  },
  {
    StructureId: 121,
    StructureCode: "0688200",
    StructureNom: "LES ARCHERS MIRECURTIENS",
    StructureNomCourt: "MIRECOURT",
    StructureCodeDepartement: "88000",
    AdresseWeb: "http://www.arcmirecourt.fr",
    AdresseFB: "",
    AdresseInsta: ""
  },
  {
    StructureId: 128,
    StructureCode: "0688208",
    StructureNom: "COMPAGNIE DES ARCHERS DE NEUFCHATEAU",
    StructureNomCourt: "NEUFCHATEAU",
    StructureCodeDepartement: "88000",
    AdresseWeb: "",
    AdresseFB: "",
    AdresseInsta: ""
  },
  {
    StructureId: 123,
    StructureCode: "0688203",
    StructureNom: "1ERE COMPAGNIE D'ARC DE SAINT-DIE-DES-VOSGES",
    StructureNomCourt: "ST DIE",
    StructureCodeDepartement: "88000",
    AdresseWeb: "http://club-arc-saint-die.fr",
    AdresseFB: "",
    AdresseInsta: ""
  },
  {
    StructureId: 119,
    StructureCode: "0688192",
    StructureNom: "EST TIR A L'ARC",
    StructureNomCourt: "THAON LES VOSGES",
    StructureCodeDepartement: "88000",
    AdresseWeb: "https://www.esthaon-tiralarc.com/",
    AdresseFB: "",
    AdresseInsta: ""
  },
  {
    StructureId: 122,
    StructureCode: "0688201",
    StructureNom: "1E CIE DES HTES VOSGES",
    StructureNomCourt: "VAGNEY",
    StructureCodeDepartement: "88000",
    AdresseWeb: "",
    AdresseFB: "",
    AdresseInsta: "https://www.instagram.com/tiralarcvagney2022"
  },
  {
    StructureId: 127,
    StructureCode: "0688207",
    StructureNom: "LA FLECHE THERMALE",
    StructureNomCourt: "VITTEL",
    StructureCodeDepartement: "88000",
    AdresseWeb: "",
    AdresseFB: "",
    AdresseInsta: ""
  },
  {
    StructureId: 126,
    StructureCode: "0688206",
    StructureNom: "VALLEE DES LACS",
    StructureNomCourt: "XONRUPT LONGEMER",
    StructureCodeDepartement: "88000",
    AdresseWeb: "",
    AdresseFB: "",
    AdresseInsta: ""
  }
]
